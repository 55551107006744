<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete"><i class="el-icon-delete"></i> 删除</el-button>
        <el-button class="vd_export" size="small" type="info" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新</el-button>
        <el-button icon="el-icon-document-copy" size="small" type="warning" @click="copyRow">复制并编辑</el-button>
      </div>
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="`clau_id`"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        :need-fixed-height="true"
        @selection-change="handleSelectionChange"
        @getTableData="getRequs"
        @row-dblclick="dbClickJp"
      >
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
      </search-table>
    </el-card>
  </div>
</template>

<script>
import { getNoCatch, postNoCatch } from '@api/request';
import { qutbAPI } from '@api/modules/qutb';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { getDateNoTime } from '@assets/js/dateUtils';
import { getArrayIds } from '@assets/js/arrayUtils';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'QutbList',
  components: {
    SearchTable
  },
  data() {
    return {
      tableProperties: [
        { prop: 'selection', type: 'selection', labelWidth: '48px' },
        { prop: 'qutb_no', label: '报价单号', itemType: 'input', widthAuto: true },
        { prop: 'requ_no', label: '委托打样单号', itemType: 'input', widthAuto: true },
        { prop: 'fequ_no', label: '工厂打样单号', itemType: 'input', widthAuto: true },
        { prop: 'prod_name', label: '样品名称', itemType: 'input', widthAuto: true },
        {
          prop: 'qutb_made',
          label: '样品材质类型',
          itemType: 'input',
          input: false,
          widthAuto: true
        },
        { prop: 'prod_no', label: '我司货号', itemType: 'input', widthAuto: true },
        { prop: 'prod_suffix', label: '更改号', input: false, itemType: 'input', widthAuto: true },
        { prop: 'qutb_pricer_name', label: '核价接收人', itemType: 'input', widthAuto: true },
        { prop: 'qutb_pridate', label: '核价接收日期', itemType: 'date', widthAuto: true, formatter: val => getDateNoTime(val, true) },
        { prop: 'qutb_total', label: '含税价格', input: false, itemType: 'input', widthAuto: true },
        { prop: 'stff_name', label: '录入人', itemType: 'input', widthAuto: true },
        { prop: 'create_time', label: '录入时间', itemType: 'date', widthAuto: true, formatter: val => getDateNoTime(val, true) },
        {
          prop: 'status',
          label: '单据状态',
          itemType: 'select',
          fixed: 'right',
          options: [
            { value: 0, label: '草拟' },
            { value: 1, label: '在批' },
            { value: 2, label: '生效' }
          ]
        }
      ],
      loadFlag: false,
      currentPage: 1,
      totalPage: 0,
      tableData: [],
      multiSelection: [],
      custList: [],
      btn: { delete: true },
      requDeptForm: {},
      stffUserList: [],
      qutbMade: [
        // {下拉 1=尼龙，2=TPR/TPE，3=橡胶-尼龙无损耗-TPR/TPE中损耗-橡胶大损耗}
        { value: 1, label: '尼龙' },
        { value: 2, label: 'TPR/TPE' },
        { value: 3, label: '橡胶' }
      ],
      tableMaxHeight: 0
    };
  },
  mounted() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/qutb_add' || from.path === '/qutb_edit') {
        this.initData();
      }
    }
  },
  methods: {
    initData() {
      this.getRequs();
    },
    // 获取公司抬头信息
    async getRequs() {
      await (this.loadFlag = true);
      getNoCatch(qutbAPI.getQutbs, this.$refs.multiTable.searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.loadFlag = false;
      });
    },
    // 刷新
    buttonRefresh() {
      this.$refs.multiTable.resetFields();
      this.getRequs();
    },
    // 多选获取勾选内容
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },

    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        this.mBox();
      }
    },
    //删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delRow();
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
          this.$refs.multiTable.clearSelection();
        });
    },
    // 删除
    delRow() {
      postNoCatch(qutbAPI.deleteQutbByIds, { qutb_id_list: getArrayIds(this.multiSelection, 'qutb_id') }).then(({ data }) => {
        this.$message.success('删除成功!');
        this.initData();
      });
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      const permId = this.$route.query.perm_id;
      this.jump('/qutb_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: permId,
            form_id: row.qutb_id
          })
        )
      });
    },
    // 样品材质类型
    qutbMadeFormmater(qutb_made) {
      let str = '';
      this.qutbMade.forEach(item => {
        if (item.value === qutb_made) {
          str = item.label;
        }
      });
      return str;
    },
    copyRow() {
      if (this.multiSelection.length !== 1) return this.$message.warning('只能选择一条');
      getNoCatch(qutbAPI.copy_qutb_by_id, { qutb_id: this.multiSelection[0].qutb_id }).then(({ data }) => {
        const permId = this.$route.query.perm_id;
        this.jump('/qutb_edit', {
          key: UrlEncode.encode(
            JSON.stringify({
              perm_id: permId,
              form_id: data.form_id
            })
          )
        });
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>
