import topic from '../topic';
import { getNoCatch } from '@api/request';

export const qutbAPI = {
  getQutbs: topic.qutbTopic + '/get_qutbs',
  deleteQutbByIds: topic.qutbTopic + '/delete_qutb_by_ids',
  getQutbById: topic.qutbTopic + '/get_qutb_by_id',
  addQutb: topic.qutbTopic + '/add_qutb',
  editQutb: topic.qutbTopic + '/edit_qutb',
  exportQutbPdfById: topic.qutbTopic + '/pdf/export_qutb_pdf_by_id',
  copy_qutb_by_id: topic.qutbTopic + '/copy_qutb_by_id',
  get_fequ_part: topic.qutbTopic + '/get_fequ_part',
  export_qutb: params => getNoCatch(`${topic.qutbTopic}/export_qutb`, params)
};
